@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 12px;
}

.adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}